<template>
  <div class="base-text-input-container base-input">
    <label class="input-label">{{ getLabel }}</label>
    <input class="base-text-input" :type="getType" :id="getFieldValue" :required="getIsRequired" v-model="textValue" @keyup="this.$props.method($event, textValue)"/>
  </div>
</template>
<script>
export default {
  data () {
    return {
      textValue: null
    };
  },
  props: {
    type: {
      type: String
    },
    label: {
      type: String
    },
    isRequired: {
      type: Boolean
    },
    fieldValue: {
      type: String
    },
    method: {
      type: Function
    }
  },
  computed: {
    getType () {
      return this.$props.type;
    },
    getLabel () {
      return this.$props.label;
    },
    getIsRequired () {
      return this.$props.isRequired;
    },
    getFieldValue () {
      return this.$props.fieldValue;
    }
  },
};
</script>
<style scoped>
.base-text-input-container {
  display: flex;
  flex-direction: column;
}
.base-text-input {
  font-size: 16px;
  padding: .85em;
  display: block;
  width: 100%;
  background: #F7F6F1;
  border: 1px solid #D3CDC2;

}

.input-label {
  font-size: 14px !important;
}
</style>